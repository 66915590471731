import { template as template_7b38099e377c43b8bbbb541b61eab830 } from "@ember/template-compiler";
const FKLabel = template_7b38099e377c43b8bbbb541b61eab830(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
