import { template as template_3c1a5287d0844222b77a4d3fa0f3b3d6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3c1a5287d0844222b77a4d3fa0f3b3d6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
