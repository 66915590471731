import { template as template_046150f64ad2433295c1a66b732d3424 } from "@ember/template-compiler";
const WelcomeHeader = template_046150f64ad2433295c1a66b732d3424(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
