import { template as template_b14cdea420874107b7936f24c5af77aa } from "@ember/template-compiler";
const FKText = template_b14cdea420874107b7936f24c5af77aa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
